import Dashboard from "../components/Dashboard";
import Edit from "../components/Edit";

export const PrivateRoutes = [
    {
        path: "/dashboard",
        element: <Dashboard />,
        
    },
    {
        path: "/edit",
        element: <Edit/>,
        
    },
];
