import React, { useState } from 'react'
import { Container, Row, Col, } from 'react-bootstrap'
import { reset, message } from '../../../AllImages'
import { useNavigate } from 'react-router-dom'
import Footer from '../../../UI/Footer'
import { MainHeading, SiteLogo } from './elements'
import axios from 'axios';

const Reset = () => {
    const naviagte = useNavigate()
    const [email, setEmail] = useState()
    const value = {
        mainHeading: "Reset Password",
        paragraph: "Password reset link will be sent to your given email."
    }
    const resetApi = async () => {
        try {
            const response = await axios.post("https://apingweb.com/api/forgot-password", email)
            if (!response.data) {
                throw new Error('Response error plz check the response');
            }
            else {
                console.log('API Response:', response);
                // navigate('/change-password')
            }
        } catch (error) {
            console.log(error.message)
        }
    }
    const resetPassword = (e) => {
        e.preventDefault();
        resetApi()
    }
    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <SiteLogo />
                        <div className="login-content">
                            <MainHeading mainHeading={value.mainHeading} paragraph={value.paragraph} />
                            <form onSubmit={resetPassword} >
                                <div className='form'>
                                    <div className='form-group'>
                                        <span className='login-icons'>
                                            <img src={message} alt="icon" />
                                        </span>
                                        <input className="form-control color-theme-light" type="email" name='email' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <button type="submit" className='btn-yellow'>Reset Password</button>
                                    <span className="forget-link" onClick={() => naviagte('/login')}>Back to login</span>
                                </div>
                            </form>
                        </div>
                    </Col>
                    <Col>
                        <div className="login-bg">
                            <img src={reset} alt="Img" />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default Reset
