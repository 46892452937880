import React, { useEffect } from "react";
import "./Style/Style.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { authorRoutesConfig } from './Routes/authorRoutes'
import Adminlayout from "./components/Adminlayout";
function App() {
  const location = useLocation();
  useEffect(() => {
    window.scroll({ top: 0, behaviour: "smooth" });
  }, [location]);
  return (
    <>
      <Routes>
        
        {authorRoutesConfig.map((list, key) => (
          <Route key={key} {...list} />
        ))}
        <Route path="/*" element={<Adminlayout />} />
      </Routes>
    </>
  );
}

export default App;
