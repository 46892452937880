import { dashboard, customer } from '../AllImages'
const sidebarDataConfig = [
    {
        url: "/dashboard",
        icon: <img src={dashboard} alt="icon" />,
        lable: "Dashboard",
        exact: "/"
    },
    {
        url: "/edit",
        icon: <img src={customer} alt="icon" />,
        lable: "Add User"
    },
]
export default sidebarDataConfig