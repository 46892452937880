import React from 'react'
import { Col, Container, Row, Dropdown } from 'react-bootstrap'
import { avater, notification } from '../AllImages'
import { useNavigate } from 'react-router-dom';

const Topbar = () => {
  const navgation = useNavigate();
  const logout = () => {
    localStorage.clear()
    navgation('/')
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className='page-topbar'>
              <div className='pageTitle'> Menu </div>
              <Dropdown>
                <Dropdown.Toggle className='toggle-btn' id="dropdown-basic">
                  <img src={avater} alt="" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>Admin</Dropdown.Item>
                  <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Topbar
