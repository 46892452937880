import React, { useState } from 'react'
import { message, lock } from '../../../../AllImages'
import { useNavigate, } from 'react-router-dom';
import axios from 'axios';
import { Alert } from 'react-bootstrap';

const RegisterForm = () => {
    const navigate = useNavigate();
    const [error, setError] = useState()
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
    })
    const handInput = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value })
    }
    const getApi = async () => {
        try {
            const response = await axios.post("https://apingweb.com/api/register", formData)
            if (!response.data) {
                alert(" something is worng! plz check console")
                throw new Error('Response error plz check the response');
            }
            else {
                alert("your Account Register Successfully ")
                console.log('API Response:', response);
                navigate('/dashboard')
            }
        } catch (error) {
            alert(" something is worng! plz check console")
            console.log(error.message)
            setError(error.message)
        }
    }

    const registerForm = (e) => {
        e.preventDefault();
        getApi()
    }
    return (
        <>
            {/* {error !== '' && <h5>{error}</h5>} */}
            <form onSubmit={registerForm} >
                <div className='form'>
                    <div className='form-group'>
                        <span className='login-icons'>
                            <img src={message} alt="icon" />
                        </span>
                        <input className="form-control color-theme-light" type="Text" placeholder='Name' name='name' value={formData.name} onChange={handInput} />
                    </div>
                    <div className='form-group'>
                        <span className='login-icons'>
                            <img src={message} alt="icon" />
                        </span>
                        <input className="form-control color-theme-light" type="email" placeholder='email' name='email' value={formData.email} onChange={handInput} />
                    </div>
                    <div className='form-group'>
                        <span className='login-icons'>
                            <img src={lock} alt="icon" />
                        </span>
                        <input className="form-control color-theme-light" type="password" placeholder='password' name='password' value={formData.password} onChange={handInput} />
                    </div>
                    <div className='form-group'>
                        <span className='login-icons'>
                            <img src={lock} alt="icon" />
                        </span>
                        <input className="form-control color-theme-light" type="password" name='password_confirmation' placeholder='Confirm-password' value={formData.password_confirmation} onChange={handInput} />
                    </div>
                    <button type="submit" className='btn-yellow'>Sign up</button>
                    <span className="forget-link" onClick={() => navigate('/login')}>Login</span>
                </div>
            </form >
        </>
    )
}

export default RegisterForm
