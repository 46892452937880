import React, { useState } from 'react'
import { Container, Row, Col, } from 'react-bootstrap'
import { changeImg, lock } from '../../../AllImages'
import Footer from '../../../UI/Footer'
import { MainHeading, SiteLogo } from './elements'

const formData = [
    {
        icon: <img src={lock} alt="icon" />,
        placeholder: "Old Password",
    },
    {
        icon: <img src={lock} alt="icon" />,
        placeholder: "Old Password",
    },
    {
        icon: <img src={lock} alt="icon" />,
        placeholder: "Confirm Password",
    },

]

const Changepassword = () => {
    const [password, setPassword] = useState('')
    const formSubmit = (e) => {
        e.preventDefault();
    }
    const passowrdEvent = (e) => {

        setPassword(e.target.value)
    }
    const value = {
        mainHeading: "Password Change",
        paragraph: "Please enter your old and new password to change password"
    }
    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <SiteLogo />
                        <div className="login-content">
                            <MainHeading mainHeading={value.mainHeading} paragraph={value.paragraph} />
                            <form onSubmit={formSubmit}>
                                {formData.map((data, key) => (
                                    <div key={key} className='form-group'>
                                        <span className='login-icons'>{data.icon}</span>
                                        <input className="form-control color-theme-light" type="Password" placeholder={data.placeholder} value={password} onChange={passowrdEvent} />
                                    </div>
                                ))}
                                <button type="submit" className='btn-yellow'>Submit</button>
                            </form>
                        </div>
                    </Col>
                    <Col>
                        <div className="login-bg">
                            <img src={changeImg} alt="Img" />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default Changepassword
