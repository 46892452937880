import React from 'react'
import { Link } from 'react-router-dom'
import { favicon, logoPRM } from "../../../AllImages"

export const SiteLogo = () => {
    return (
        <>
            <Link className="logo" to='/'>
                <img className="favicon" src={favicon} alt="siteLogo" />
                <img src={logoPRM} alt="siteLogo" />
            </Link>
        </>
    )
}
export const MainHeading = (props) => {
    return (
        <>
            <span className='main-heading color-theme'> {props.mainHeading}</span>
            <div className='heading-content'>{props.paragraph}</div>
        </>
    )
}
