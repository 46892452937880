import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
      <Container fluid >
        <Row>
          <Col className="Footer">
            © 2023 <Link to='/' className='color-green bold'>PRM</Link> All rights reserved.
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Footer
