import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Modal } from 'react-bootstrap'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Edit from './Edit';

const Dashboard = () => {
  const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleOpen = () => setShow(true);
  const [data, setData] = useState([]);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [age, setAge] = useState();
  const [updateId, setUpdateId] = useState();
  const getUser = async () => {
    try {
      const response = await axios.get('https://apingweb.com/api/users')
      if (!response.data) {
        throw new Error('Response error plz check the response');
      }
      else {
        console.log('User Data Response:', response.data.data);
        const result = response.data.data;
        setData(result)
        setName(result[0].name)
        setEmail(result[0].email)
        setAge(result[0].age)
        setUpdateId(result[0].user_id)
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    getUser()
  }, [])

  const editApi = (id) => {
    const inputData = data[id - 285]
    setName(inputData.name)
    setEmail(inputData.email)
    setAge(inputData.age)
    setUpdateId(inputData.user_id)
    handleOpen()
  }
  const updateApi = async () => {
    const updataData = { updateId, name, email, age }
    console.log(updataData)
    try {
      const response = await axios.put(`https://apingweb.com/api/user/edit/${updateId}`, updataData)
      if (!response.data) {
        throw new Error('Response error plz check the response');
      }
      else {
        alert("Edit the User Successfully")
        console.log('User Data delete Response:', response.data.data);
        const result = response.data.data;
        getUser(result)
      }
    } catch (error) {
      console.log(error.message)
    }
    handleClose()
  }
  const deleteUser = async (id) => {
    try {
      const response = await axios.delete(`https://apingweb.com/api/user/delete/${id}`)
      if (!response.data) {
        throw new Error('Response error plz check the response');
      }
      else {
        alert("User delete Successfully")
        console.log('User Data delete Response:', response.data.data);
        getUser()
      }
    } catch (error) {
      console.log(error.message)
    }
  }
  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className='main-page-heading'>
              <h3>Dashboard</h3>
              <span className='btn btn-outline-warning' onClick={() => navigate(`/edit`)}>Add User</span>
            </div>
            <div className='card-common'>
              <div className='card-topbar-inner'>Dashboard</div>
              <table className='table tableCommon'>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Email</th>
                    <th>Image</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.map((list, i) => (
                      <tr key={i}>
                        <td>{list.user_id}</td>
                        <td>{list.name}</td>
                        <td>{list.age}</td>
                        <td>{list.email}</td>
                        <td>{list.image}</td>
                        <td><button type="button" className='btn btn-outline-success' onClick={() => editApi(list.user_id)}>Edit</button></td>
                        <td><button type="button" className='btn btn-outline-danger' onClick={() => deleteUser(list.user_id)}>Delete</button></td>
                      </tr>
                    ))
                  }

                </tbody>
                <tfoot>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Email</th>
                    <th>Image</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Model</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='card-common'>
            <div className='card-topbar-inner'>Edit</div>
            <div className='from-row'>
              <div className='input-field'>
                <div className="input-label">
                  <label>Name</label>
                  <input type="text" value={name} className="edit-form-control" placeholder="Name" onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="input-label">
                  <label>Email</label>
                  <input type="email" value={email} className="edit-form-control" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                </div>
              </div>
              <div className="input-label">
                <label>Age</label>
                <input type="text" value={age} className="edit-form-control" placeholder="Age" onChange={(e) => setAge(e.target.value)} />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-outline-danger' onClick={handleClose}>
            Close
          </button>
          <button className='btn btn-outline-success' onClick={updateApi}>
            Update
          </button>
        </Modal.Footer>
      </Modal >
    </>
  )
}

export default Dashboard

