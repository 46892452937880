import React, { useEffect } from 'react'
import Topbar from '../UI/Topbar'
import Sidebar from '../UI/Sidebar'
import Footer from '../UI/Footer'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { PrivateRoutes } from '../Routes/PrivateRoutes'


const Adminlayout = () => {
    const navigation = useNavigate()
    return (
        <>
            <div className='bg-color'>
                <Topbar />
                <Sidebar />
                <div className='main-content-container'>
                    {<Routes>
                        {PrivateRoutes.map((list, key) => (
                            <Route key={key} {...list} />
                        ))}
                    </Routes>}
                </div>
                {/* <Footer /> */}
            </div>
        </>
    )
}
export default Adminlayout
