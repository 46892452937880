import React from "react";
import { SiteLogo } from "../components/Pages/author/elements";
import { NavLink, useLocation } from "react-router-dom";
import sidebarDataConfig from "./SidebarData";
const Sidebar = () => {
  const location = useLocation()
  return (
    <>
      <div className="sidebar">
        <SiteLogo />
        <div className="sidebar-nav">
          <ul className="main-category-menu">
            {
              sidebarDataConfig.map((data, key) => {
                return (
                  <li key={key}>
                    <NavLink to={data.url} className="sidebar-nav-section" activeClassName="active">
                      <span>{data.icon}</span>
                      <span className="sidebar-heading">{data.lable}</span>
                    </NavLink>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div >
    </>
  );
};

export default Sidebar;
