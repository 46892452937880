import React, { useEffect } from 'react'
import { Container, Row, Col, } from 'react-bootstrap'
import { lockimg, } from '../../../../AllImages'
import Footer from '../../../../UI/Footer'
import { MainHeading, SiteLogo } from '../elements'
import RegisterForm from './registerForm'
import { useNavigate } from 'react-router-dom';

const Register = () => {

    const naviagtion = useNavigate();
    useEffect(() => {
        if (localStorage.getItem("user-info")) {
            naviagtion("/dashboard")
        }
    })
    
    const value = {
        mainHeading: "Register Your Account",
        paragraph: "Please enter your Name email and password to sign up to dashboard"
    }
    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <SiteLogo />
                        <div className="login-content">
                            <MainHeading mainHeading={value.mainHeading} paragraph={value.paragraph} />
                            <RegisterForm />
                        </div>
                    </Col>
                    <Col>
                        <div className="login-bg">
                            <img src={lockimg} alt="Img" />
                        </div>
                    </Col>
                </Row >
            </Container >
            <Footer />
        </>
    )
}

export default Register
