import React, { useState } from 'react'
import { message, lock } from '../../../../AllImages'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Loginform = () => {
    const navigate = useNavigate();
    const [loginData, setLoginData] = useState({
        email: "",
        password: "",
    });
    const LoginHandle = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setLoginData((list) => {
            return { ...list, [name]: value }
        })
    }
    const getloginApi = async () => {
        try {
            const response = await axios.post("https://apingweb.com/api/login", loginData)
            if (!response.data) {
                alert(" something is worng! plz check console")
                throw new Error('Response error plz check the response');
            }
            else {
                alert("your Login Successfully  ")
                console.log('Login API Response:', response);
                navigate('/dashboard')
            }
        } catch (error) {
            alert(" something is worng! plz check console")
            console.log(error.message)
        }
    }

    const submit = (e) => {
        e.preventDefault();
        getloginApi()
    }
    return (
        <>
            <form onSubmit={submit} >
                <div className='form'>
                    <div className='form-group'>
                        <span className='login-icons'>
                            <img src={message} alt="icon" />
                        </span>
                        <input className="form-control color-theme-light" type="Text" placeholder='email' name='email' value={loginData.email} onChange={LoginHandle} />
                    </div>
                    <div className='form-group'>
                        <span className='login-icons'>
                            <img src={lock} alt="icon" />
                        </span>
                        <input className="form-control color-theme-light" type="password" placeholder='password' name='password' value={loginData.password} onChange={LoginHandle} />
                    </div>
                    <button type="submit" className='btn-yellow'>Login</button>
                    <span className="forget-link" onClick={() => navigate('/reset-password')}>Forgrt Password?</span>
                </div>
            </form >
        </>
    )
}

export default Loginform
