import Changepassword from "../components/Pages/author/Changepassword";
import Reset from "../components/Pages/author/Reset";
import Login from "../components/Pages/author/login/login";
import Register from "../components/Pages/author/register/register";

export const authorRoutesConfig = [
  {
    path: "/",
    element: <Register/>,
    expect: true,
  },
  {
    path: "/login",
    element: <Login />,

  },
  {
    path: "/reset-password",
    element: <Reset />,
  },
  {
    path: "/change-password",
    element: <Changepassword />,
  },
];
