import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const Edit = () => {
    const navgate = useNavigate();
    // const { getUser } = useParams();
    const [addUser, setAddUser] = useState({
        name: "",
        email: "",
        age: "",
        image: "",
    })
    const addUserHandle = (e) => {
        const { name, value } = e.target;
        setAddUser({ ...addUser, [name]: value })
    }
    const userAdd = async () => {
        try {
            const response = await axios.post('https://apingweb.com/api/user/create', addUser)
            if (!response.data) {
                throw new Error('Response error plz check the response');
            }
            else {
                alert('New User Created')
                console.log('User Data Response:', response.data);
                const result = response.data.data;
            }
        } catch (error) {
            console.log(error.message)
        }
    }
    const addSubmit = (e) => {
        e.preventDefault();
        userAdd()
    }
    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <div className='main-page-heading'>
                            <h3>Add User</h3>
                            <span className='btn btn-outline-warning' onClick={() => navgate('/dashboard')}>Back</span>
                        </div>
                        <div className='card-common'>
                            <div className='card-topbar-inner'>Add Users</div>
                            <form onSubmit={addSubmit} className='from-row'>
                                <div className='input-field'>
                                    <div className="input-label">
                                        <label>Name</label>
                                        <input type="text" value={addUser.name} name='name' className="edit-form-control" onChange={addUserHandle} placeholder="Name" />
                                    </div>
                                    <div className="input-label">
                                        <label>Email</label>
                                        <input type="email" value={addUser.email} name='email' className="edit-form-control" onChange={addUserHandle} placeholder="Email" />
                                    </div>
                                </div>
                                <div className='input-field'>
                                    <div className="input-label">
                                        <label>Age</label>
                                        <input type="number" value={addUser.age} name='age' className="edit-form-control" onChange={addUserHandle} placeholder="Enter Age" />
                                    </div>
                                    <div className="input-label">
                                        <label>Image</label>
                                        <input type="file" value={addUser.image} name='image' className="edit-form-control" onChange={addUserHandle} placeholder="Image (Optional)" />
                                    </div>
                                </div>
                                <div className="btnWrapperPrimary">
                                    <button type="submit" className='btn btn-outline-success'>
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Edit
