import React, { useEffect } from 'react'
import { Container, Row, Col, } from 'react-bootstrap'
import { lockimg, } from '../../../../AllImages'
import Footer from '../../../../UI/Footer'
import { MainHeading, SiteLogo } from '../elements'
import Loginform from './loginform'
import { useNavigate } from 'react-router-dom';

const Login = () => {

  const naviagtion = useNavigate()
  useEffect(() => {
    if (localStorage.getItem("user-info")) {
      naviagtion("/dashboard")
    }
  })
  const value = {
    mainHeading: "Welcome Admin",
    paragraph: "Please enter your email and password to login to dashboard"
  }
  return (
    <>
      <Container>
        <Row>
          <Col>
            <SiteLogo />
            <div className="login-content">
              <MainHeading mainHeading={value.mainHeading} paragraph={value.paragraph} />
              <Loginform />
            </div>
          </Col>
          <Col>
            <div className="login-bg">
              <img src={lockimg} alt="Img" />
            </div>
          </Col>
        </Row >
      </Container >
      <Footer />
    </>
  )
}

export default Login
